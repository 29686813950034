// color
$key: #0c3b2c;
$key_sub: #3f665c;
$point: #bb8a52;
$white: #f9f8f3;
$gray: #eae8e3;
$red: #dd5757;

// font
$noto: 'Noto Sans JP', sans-serif;
$gilroy: Gilroy;
$gilroySemi: Gilroy-SemiBold;
$gilroyExtra: Gilroy-ExtraBold;
$bold: 800;
$semi: 600;

// ease
$easeInOut: cubic-bezier(0.4, 0, 0, 1);
$easeOut: cubic-bezier(0, 1, 0.4, 1);

// @media
$pc: 1600px;
$mid: 1440px;
$sp: 768px;
