@import '../../../utils/variables';

.container {
    width: auto;
    margin: 0 2rem 15rem;
}
.img {
    position: relative;
    width: 156rem;
    height: 60rem;
    margin: 0 0 7rem;
}
.imgText {
    position: absolute;
    top: 29.55rem;
    left: 14rem;
    color: $white;
    font-size: 3rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.155rem;
    z-index: 1;
}
.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 96rem;
    margin: 0 auto;
}
.contentText {
    width: 40rem;
    color: $key;
    font-size: 1.3rem;
    letter-spacing: 0.0875rem;
    line-height: 2;
}

//
//
//

@media screen and (min-width: $pc) {
    .img {
        width: 97.5vw;
        height: 37.5vw;
    }
    .imgText {
        top: 18.5vw;
        left: 8.75vw;
        font-size: 1.875vw;
    }
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        margin: 0 0.9rem 10rem;
    }
    .img {
        width: 100%;
        height: 35.7rem;
        margin: 0 0 3rem;
    }
    .imgText {
        top: 16.35rem;
        left: 3.8rem;
        font-size: 2.4rem;
        letter-spacing: 0.235rem;
    }
    .content {
        flex-direction: column;
        width: 30rem;
    }
    .contentText {
        width: 100%;
        margin: 0 0 3rem;
        line-height: 1.7;
    }
}
