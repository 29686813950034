@import '../../../utils/variables';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray;
}
.link {
    margin: 6rem 0;
}
.img {
    position: relative;
    width: 96rem;
    height: 35rem;
    overflow: hidden;
}

//
//
//

@media screen and (max-width: $sp) {
    .link {
        margin: 5rem 0;
    }
    .img {
        width: 35.7rem;
        height: 13.1rem;
    }
}
