@import '../../utils/variables';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    min-width: 20rem;
    height: 100%;
    min-height: 6rem;
    padding: 2.15rem;
    background-color: $key_sub;
    border-radius: 4rem;
}
.text {
    color: $white;
    font-size: 1.3rem;
    font-family: $gilroySemi;
    letter-spacing: 0.115rem;
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        min-width: 12rem;
        min-height: 4rem;
        padding: 1.35rem;
    }
    .text {
        font-size: 1.1rem;
    }
}
