@import '../../../utils/variables';

.container {
    width: auto;
    margin: 0 2rem 15rem;
}
.wrapper {
    display: flex;
    flex-direction: row;
}
.img {
    position: relative;
    width: 78rem;
    height: 100rem;
}
.imgText {
    position: absolute;
    top: 28.5rem;
    left: 21.9rem;
    color: $white;
    font-size: 2.4rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.235rem;
    z-index: 1;
}
.content {
    width: 60rem;
    margin: 21.5rem 0 1.25rem 8rem;
}

.head {
    margin: 0 0 4rem;
}
.headTitle {
    color: $key;
    font-size: 5rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.25rem;
    line-height: 1.2;
}
.bodyText {
    margin: 0 0 5.25rem;
}
.bodyTextInside {
    display: block;
    margin: 0 0 2.55rem;
    font-size: 1.3rem;
    letter-spacing: 0.0585rem;
    line-height: 2;
}
.bodyTextInsideLink {
    position: relative;
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $key;
    }
}
.bodyIntro {
    width: 20rem;
    height: 23.5rem;
    margin: 0 0 6rem;
}
.bodyIntroImgPc {
    width: 15.4rem;
    height: 19.7rem;
}
.bodyFeatureTitle {
    margin: 0 0 2.25rem;
    color: $key;
    font-size: 3rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.155rem;
}
.bodyFeatureItem {
    position: relative;
    padding: 0 0 0 2rem;
    color: $key;
    font-size: 1.3rem;
    letter-spacing: 0.0585rem;
    line-height: 2;
    &::before {
        position: absolute;
        top: 1.15rem;
        left: 0.825rem;
        content: '';
        width: 3px;
        height: 3px;
        background-color: $key;
        border-radius: 50%;
    }
}

//
//
//

@media screen and (min-width: $pc) {
    .img {
        width: 48.75vw;
        height: 62.5vw;
    }
    .imgText {
        top: 17.85vw;
        left: 13.7vw;
        font-size: 1.5vw;
    }
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        width: 100%;
        margin: 0 auto 10rem;
    }
    .wrapper {
        flex-direction: column;
    }
    .img {
        width: 100%;
        height: 60rem;
        margin: 0 0 5rem;
    }
    .imgText {
        top: 17.1rem;
        left: 5.6rem;
    }
    .content {
        width: 30rem;
        margin: 0 auto;
    }
    .head {
        margin: 0 0 3rem;
    }
    .headTitle {
        font-size: 3rem;
        letter-spacing: 0.155rem;
        line-height: 1.325;
    }
    .bodyText {
        margin: 0 0 3rem;
    }
    .bodyTextInside {
        margin: 0 0 1.55rem;
        line-height: 1.7;
    }
    .bodyIntro {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: auto;
        margin: 0 0 5.45rem;
    }
    .bodyIntroImgSpIos {
        width: 13.595rem;
        height: 4.401rem;
    }
    .bodyIntroImgSpAndroid {
        width: 14.7rem;
        height: 4.4rem;
    }
    .bodyFeatureTitle {
        margin: 0 0 1.25rem;
        font-size: 2rem;
        letter-spacing: 0.2rem;
    }
    .bodyFeatureItem {
        padding: 0 0 0 1.6rem;
        font-size: 1.1rem;
        line-height: 1.825;
        &::before {
            top: 1rem;
            left: 0.6rem;
        }
    }
}
