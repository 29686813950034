@import '../../utils/variables';

.container {
    display: flex;
    align-items: center;
    height: 6.85rem;
    padding: 0 5.5rem;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
}
.list {
    display: flex;
    flex-direction: row;
}
.item {
    &:last-child {
        .link {
            pointer-events: none;
            &::before {
                content: none;
            }
            &::after {
                content: none;
            }
        }
    }
}
.link {
    position: relative;
    display: block;
    width: max-content;
    margin: 0 0.45rem 0 0;
    padding: 0.5rem;
    color: $key;
    font-size: 1.1rem;
    font-family: $gilroySemi;
    letter-spacing: 0.115rem;
    line-height: 0.9;
    &::before {
        position: absolute;
        top: 0.35rem;
        right: -0.15rem;
        content: '';
        width: 1px;
        height: 1.2rem;
        background-color: $key;
        transform: rotate(25deg);
    }
    &::after {
        position: absolute;
        bottom: 0.55rem;
        left: 0.5rem;
        content: '';
        width: calc(100% - 1rem);
        height: 1px;
        background-color: $key;
    }
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        height: 5rem;
        padding: 0;
    }
    .list {
        min-width: 31rem;
        margin: 0 auto;
    }
    .link {
        margin: 0 0.35rem 0 0;
        font-size: 1rem;
        letter-spacing: 0.1rem;
    }
}
